const userPermissions = {
  CURATOR: 'curator',
  AUTHOR: 'author',
  SUPER_ADMIN: 'super_admin',
  DATA_OPS: 'data_ops',
  DATA_OPS_ONLY: 'data_ops_only',
  INSIGHTS_ONLY: 'insights_only',
  DISTRICT_COMMON: 'district-common',
  SCHOOL_COMMON: 'school-common',
}

module.exports = userPermissions
